import * as React from "react";

export interface Props {
    items: Array<any>,
    lang: string
};

export default class SimpleList extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <ul className="fa-ul mb-0">
                {this.props.items.map((item, index) => {
                    return (
                        <li key={index}>
                            <i className={item.icon}></i>
                            {item.name[this.props.lang]}
                        </li>
                    );
                })}
            </ul>
        );
    }
}
