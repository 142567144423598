import * as React from "react";

export interface Props {
    text: string;
    lang: string;
};

export default class Paragraph extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <p>
                {this.props.text[this.props.lang]}
            </p>
        );
    }
}
