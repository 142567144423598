import * as React from "react";

export interface Props {
    items: Array<any>;
    lang: string;
};

export default class IconList extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <ul className="list-inline dev-icons">
                {this.props.items.map((item, index) => {
                    return (
                        <li className="list-inline-item" key={index}>
                            {
                                item.name != null ?
                                    <i data-toggle="tooltip" data-placement="top" title={item.name[this.props.lang]} className={item.icon}></i>
                                    :
                                    <i className={item.icon}></i>
                            }
                        </li>
                    );
                })}
            </ul>
        );
    }
}
