import * as React from "react";

export interface Props {
    firstName: string;
    lastName: string;
    cityCountryName: string;
    phoneNumber: string;
    email: string;
    Iam: string;
    jobs: string;
    shortIntro: string;
    lang: string;
};

export default class About extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className="my-auto">
                <h2 className="mb-0">
                    {this.props.firstName}
                    <span className="text-primary">
                        {this.props.lastName}
                    </span>
                </h2>
                <div className="subheading mb-5">
                    {this.props.cityCountryName} · <a href={"mailto:" + this.props.email}>{this.props.email}</a>
                </div>

                <p className="lead">
                    {this.props.Iam[this.props.lang]+" "}
                    <a className="text-primary typewrite" data-period="2000" data-type={this.props.jobs}>
                        <span className="wrap"></span>
                    </a><span className="blink">|</span>
                </p>
                <p className="lead mb-5">{this.props.shortIntro[this.props.lang]}</p>

            </div>
        );
    }
}
