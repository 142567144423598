import * as React from "react";

export interface Props {
    items: Array<any>;
};

export default class ThreeCards extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className="row" >
                {
                    this.props.items.map((item, index) => {
                        return (
                            <div key={index} className="col-md-4" >
                                <figure className="effect-ravi">
                                    <img src={item.image} alt={item.name} />
                                    <figcaption>
                                        <h2>{item.name}</h2>
                                        <p>
                                            <a href={item.link}><i className="fa fa-search"></i></a>
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}
