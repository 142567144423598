import * as React from "react";

export interface Props {
    links: Array<any>;
    lang: string;
};

export default class ButtonsLinks extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className="social-icons">
                {this.props.links.map((item, index) => {
                    return (
                        <a href={(typeof item.link == "string") ? item.link : item.link[this.props.lang]} key={index} data-toggle="tooltip" data-placement="top" title={item.name}>
                            <i className={item.icon}></i>
                        </a>
                    );
                })}
            </div>
        );
    }
}
