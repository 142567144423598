import * as React from "react";
import ThreeCards from "./cards/ThreeCards";
import TwoCards from "./cards/TwoCards";
import OneCard from "./cards/OneCard";

export interface Props {
    items: Array<any>;
    lang: string;
};

export default class CardList extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className="grid" style={{ marginTop: 0, marginRight: "auto", marginBottom: "0", width: "95%" }}>
                {this.props.items.map((item, index) => {
                    switch (item.type) {
                        case "ThreeCards":
                            return (<ThreeCards key={index} items={item.items} />);
                        case "TwoCards":
                            return (<TwoCards key={index} items={item.items} lang={this.props.lang}/>);
                        case "OneCard":
                            return (<OneCard key={index} item={item.item} />);
                    }
                })}
            </div>
        );
    }
}
