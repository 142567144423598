import * as React from "react";

export interface Props {
    schoolName: string;
    diplomaName: string;
    level: string;
    diplomaDesc: Array<any>;
    period: string;
    link: string;
    lang: string;
};

export default class EducationRow extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className="resume-item d-flex flex-column flex-md-row mb-5">
                <div className="resume-content mr-auto">
                    {
                        this.props.link !== "" || true ?
                            <h3 className="mb-0">
                                <a href={this.props.link}>
                                    {this.props.diplomaName[this.props.lang]}
                                </a>
                            </h3>
                            :
                            <h3 className="mb-0">
                                {this.props.diplomaName[this.props.lang]}
                            </h3>
                    }
                    <span className="subheading mb-1">{this.props.schoolName}</span>
                    <div>{this.props.level != null ? this.props.level[this.props.lang] : null}</div>
                    {this.props.diplomaDesc != null ? <div>
                        <ul>
                            {this.props.diplomaDesc.map((item, index) => {
                                return (
                                    <li key={index}>
                                        {item.skill[this.props.lang]}
                                    </li>
                                );
                            })}
                        </ul></div> : null}
                </div>
                <div className="resume-date text-md-right">
                    <span className="text-primary">{this.props.period[this.props.lang]}</span>
                </div>
            </div>
        );
    }
}
