import * as React from "react";

export interface Props {
    percent: string;
    languageName: string;
    languageDesc: string;
    lang: string;
};

export default class LanguageRow extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className="mb-2">
                <ul className="fa-ul mb-0">
                    <li>
                        <i className="fa-li fas fa-globe-americas"></i>
                        <a data-toggle="tooltip" data-original-title={this.props.languageDesc[this.props.lang]}>
                            {this.props.languageName[this.props.lang]}
                        </a>
                        <div className="progress" style={{width: "50%", height: "10px"}}>
                            <div className="progress-bar bg-primary" role="progressbar" style={{width: this.props.percent}} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}

