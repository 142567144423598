import * as React from "react";

export interface Props {
    text: string;
    lang: string;
};

export default class Subtitle extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className="subheading mb-3">{this.props.text[this.props.lang]}</div>
        );
    }
}
