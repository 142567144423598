import * as React from "react";
import "../assets/scss/resume.scss";
import "../assets/js/resume.js";
import About from "../components/About";
import Paragraph from "../components/Paragraph";
import Subtitle from "../components/Subtitle";
import SimpleList from "../components/SimpleList";
import IconList from "../components/IconList";
import ExperienceRow from "../components/ExperienceRow";
import EducationRow from "../components/EducationRow";
import ButtonsLinks from "../components/ButtonLinks";
import CardList from "../components/CardList";
import LanguageRow from "../components/LanguageRow";

require('../assets/img/favicon.ico');

const profilePic = require("../assets/img/profile.jpg");

export interface AppProps {
}

export interface resumeJSON {
    languages: Array<any>;
    menu: Array<any>;
    firstName: string;
    lastName: string;
}

export interface State {
    language: string;
}

let myResume: resumeJSON = require("../resume.json");

export default class App extends React.Component<AppProps, State> {
    constructor(props) {
        super(props);
        let language = ((navigator.languages && navigator.languages[0]) ||
            navigator.language).substring(0, 2);
        this.state = {
            language: language
        };
    }

    _handleChange = (e) => {
        this.setState({ language: e.target.value });
    };

    render() {
        return (
            <div className="app">
                <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
                    <a className="navbar-brand js-scroll-trigger" href="#page-top">
                        <span className="d-block d-lg-none">{myResume.firstName} {myResume.lastName}</span>
                        <span className="d-none d-lg-block">
                            <img className="img-fluid img-profile rounded-circle mx-auto mb-2" src={profilePic} alt="" />
                        </span>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            {myResume.menu.map(((item, idx) => {
                                return (
                                    <li className="nav-item" key={idx}>
                                        <a className="nav-link js-scroll-trigger" href={item.link}>{item.title[this.state.language]}</a>
                                    </li>)
                            }))}
                            <li role="separator" className="divider"></li>
                            <li>
                                <select value={this.state.language}
                                    onChange={this._handleChange} className="selectpicker" data-width="fit" style={{ marginBottom: 20 }}>
                                    {myResume.languages.map((item, index) => {
                                        return (
                                            <option key={index} value={item.lang} data-content={'<span class="flag-icon flag-icon-' + item.icon + '"></span> ' + item.title}>{item.title}</option>
                                        );
                                    })}
                                </select>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="container-fluid p-0" >
                    {myResume.menu.map((item, index) => {
                        return (
                            <section key={index} className="resume-section p-3 p-lg-5 d-flex flex-column" id={item.link.substring(1)}>
                                <div className="my-auto">
                                    {
                                        (item.hideTitle) ?
                                            null
                                            :
                                            <h2 className="mb-5">{item.title[this.state.language]}</h2>
                                    }
                                    {item.components.map((item, index) => {
                                        switch (item.type) {
                                            case "Paragraph":
                                                return (
                                                    <Paragraph key={index} text={item.text} lang={this.state.language} />
                                                );
                                            case "Subtitle":
                                                return (
                                                    <Subtitle key={index} text={item.text} lang={this.state.language} />
                                                );
                                            case "SimpleList":
                                                return (
                                                    <SimpleList key={index} items={item.items} lang={this.state.language} />
                                                );
                                            case "LanguageRow":
                                                return (
                                                    <LanguageRow key={index} percent={item.percent} languageName={item.languageName} languageDesc={item.languageDesc} lang={this.state.language} />
                                                );
                                            case "IconList":
                                                return (
                                                    <IconList key={index} items={item.items} lang={this.state.language} />
                                                );
                                            case "CardList":
                                                return (
                                                    <CardList key={index} items={item.items} lang={this.state.language} />
                                                );
                                            case "ButtonLinks":
                                                return (
                                                    <ButtonsLinks key={index} links={item.links} lang={this.state.language} />
                                                );
                                            case "EducationRow":
                                                return (
                                                    <EducationRow key={index} schoolName={item.schoolName} level={item.level} diplomaName={item.diplomaName} diplomaDesc={item.diplomaDesc} period={item.period} link={item.link} lang={this.state.language} />
                                                );
                                            case "ExperienceRow":
                                                return (
                                                    <ExperienceRow key={index} jobTitle={item.jobTitle} companyName={item.companyName} jobDesc={item.jobDesc} jobDescList={item.jobDescList} period={item.period} link={item.link} lang={this.state.language} />
                                                );
                                            case "About":
                                                return (
                                                    <About key={index} firstName={myResume.firstName} lastName={myResume.lastName} Iam={item.Iam} jobs={item.jobs} shortIntro={item.shortIntro} cityCountryName={item.cityCountryName} phoneNumber={item.phoneNumber} email={item.email} lang={this.state.language} />
                                                );
                                        }
                                    })}
                                </div>
                            </section>
                        );
                    })};
                </div>
            </div>
        );
    }
}
