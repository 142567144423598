import * as React from "react";

export interface Props {
    item: any;
};

export default class OneCard extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className="row" >
                <div className="col">
                    <figure className="effect-ravi">
                        <img src={this.props.item.image} alt={this.props.item.name} />
                        <figcaption>
                            <h2>{this.props.item.name}</h2>
                            <p>
                                <a href={this.props.item.link}><i className="fa fa-search"></i></a>
                            </p>
                        </figcaption>
                    </figure>

                </div>
            </div>
        );
    }
}
