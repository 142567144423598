import * as React from "react";

export interface Props {
    jobTitle: string;
    companyName: string;
    jobDesc: string;
    jobDescList: Array<any>;
    period: string;
    link: string;
    lang: string;
};

export default class ExperienceRow extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className="resume-item d-flex flex-column flex-md-row mb-5">
                <div className="resume-content mr-auto">
                    <h3 className="mb-0">{this.props.jobTitle[this.props.lang]}</h3>
                    {
                        this.props.link !== "" || true ?
                            <div className="subheading mb-3"><a href={this.props.link}>{this.props.companyName}</a></div>
                            :
                            <div className="subheading mb-3">{this.props.companyName}</div>
                    }
                    {this.props.jobDesc != null ? <p>{this.props.jobDesc[this.props.lang]}</p> : null}
                    {this.props.jobDescList != null ? <p>
                            <ul>
                            {this.props.jobDescList.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <a className="subheading mb-3" href={item.infoLink}>{item.infoName}</a>
                                        <ul className="sublist"><li>{item.info[this.props.lang]}</li></ul>
                                    </li>
                                );
                            })}
                        </ul></p> : null}
                </div>
                <div className="resume-date text-md-right">
                    <span className="text-primary">{this.props.period[this.props.lang]}</span>
                </div>
            </div>
        );
    }
}
